import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import widgets from '../widgets/widgets.js';
import ReportHeader from '../../common/STAT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/STAT_Report_Footer/index.js';
import responsesQuery from './responses.graphql';
import { renderAlert } from '../../common/reportHelpers.js';
import PrintView from '../../common/PrintView/index.js';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const SMARTGOAL_MAX_CHAR_LENGTH = 180;
const PAGE_1_MAX_CHAR_LENGTH = 290;
const PAGE_2_MAX_CHAR_LENGTH = 110;

const Component = props => {
    const [reportSettings, setReportSettings] = useState({
        smart: '',
        specific: '',
        measurable: '',
        achievable: '',
        realistic: '',
        timely: '',
        barriers: [],
        solutions: [],
    });
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);
    const [showPrintMode, setShowPrintMode] = useState(false);

    const {
        widgets: {
            reportTitle: {
                props: {
                    settings: { moduleId, moduleTitle },
                },
            },
        },
    } = props;

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const data = response.data.flows.assignmentProgress.devTags;
        const dataLists = response.data.flows.assignmentProgress.devTagsLists;

        if (!data.SMARTGoal) {
            setHasModuleProgress(false);
        }

        if (data.SMARTGoal) {
            setHasModuleProgress(true);

            const barrierList = shortenResponseList(dataLists.GoalBarriers, PAGE_2_MAX_CHAR_LENGTH);
            const solutionList = shortenResponseList(dataLists.GoalSolutions, PAGE_2_MAX_CHAR_LENGTH);

            setReportSettings({
                smart: shortenResponse(data.SMARTGoal, SMARTGOAL_MAX_CHAR_LENGTH),
                specific: shortenResponse(data.SpecificGoal, PAGE_1_MAX_CHAR_LENGTH),
                measurable: shortenResponse(data.MeasurableGoal, PAGE_1_MAX_CHAR_LENGTH),
                achievable: shortenResponse(data.AchievableGoal, PAGE_1_MAX_CHAR_LENGTH),
                realistic: shortenResponse(data.RealisticGoal, PAGE_1_MAX_CHAR_LENGTH),
                timely: shortenResponse(data.TimelyGoal, PAGE_1_MAX_CHAR_LENGTH),
                barriers: barrierList,
                solutions: solutionList,
            });
        }

        setLoading(false);
    }

    const shortenResponse = (str, charLength) => {
        if (!str) {
            return '';
        }
        if (str.length > charLength) {
            let trimmedString = str.substring(0, charLength);
            // don't cut off string in the middle of a word
            trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));
            return trimmedString + '...';
        }
        return str;
    };

    const shortenResponseList = (respArray, charLength) => {
        if (!respArray) {
            return [];
        }
        return respArray
            .filter(r => r)
            .map(r => {
                if (r.length > charLength) {
                    let trimmedString = r.substring(0, charLength);

                    trimmedString = trimmedString.substr(
                        0,
                        Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
                    );
                    return trimmedString + '...';
                }
                return r;
            });
    };

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };

    if (loading) {
        return <Loader loading type="inline" />;
    }

    return (
        <div style={{ width: '750px' }}>
            <Report height="600px" downloadFilename="STAT_Module_5_Report" showPagination={showPrintMode}>
                <Page>
                    <BrowserOnly>
                        <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                    </BrowserOnly>
                    <div className={style.flexWrapper}>
                        <div>
                            <ReportHeader moduleId={moduleId} moduleTitle={moduleTitle} />
                            {hasModuleProgress ? (
                                <div className={style.board_one} style={showPrintMode ? { marginTop: '45px' } : {}}>
                                    <div className={style.smart}>{reportSettings.smart}</div>
                                    <div className={style.specific}>{reportSettings.specific}</div>
                                    <div className={style.measurable}>{reportSettings.measurable}</div>
                                    <div className={style.achievable}>{reportSettings.achievable}</div>
                                    <div className={style.realistic}>{reportSettings.realistic}</div>
                                    <div className={style.timely}>{reportSettings.timely}</div>
                                </div>
                            ) : (
                                renderAlert('Please add a SMART Goal to see this report', 'hourglass')
                            )}
                            {!showPrintMode && <ReportFooter />}
                        </div>
                    </div>
                </Page>
                <Page>
                    <div className={style.flexWrapper}>
                        <div>
                            <ReportHeader moduleId={moduleId} moduleTitle={moduleTitle} />
                            {hasModuleProgress ? (
                                <div className={style.board_two}>
                                    <ul className={style.responses} id={style.barriers}>
                                        {reportSettings.barriers.map((barrier, i) => {
                                            return <li key={i}>{barrier}</li>;
                                        })}
                                    </ul>
                                    <ul className={style.responses} id={style.solutions}>
                                        {reportSettings.solutions.map((barrier, i) => {
                                            return <li key={i}>{barrier}</li>;
                                        })}
                                    </ul>
                                </div>
                            ) : (
                                renderAlert('Please add a SMART Goal to see this report', 'hourglass')
                            )}
                            {!showPrintMode && <ReportFooter />}
                        </div>
                    </div>
                </Page>
            </Report>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    widgetsLoaded: PropTypes.array,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
})(Component);
