import React from 'react';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import PropTypes from 'prop-types';

const BarChart = props => {
    const { timepoint } = props;

    const chartData = [
        {
            measure: 'Intrusion',
            component: timepoint.Intrusion,
        },
        {
            measure: 'Avoidance',
            component: timepoint.Avoidance,
        },
        {
            measure: 'Arousal',
            component: timepoint.Arousal,
        },
    ];

    return (
        <div style={{ width: '370px', height: '300px' }}>
            <ResponsiveBar
                data={chartData}
                keys={['component']}
                indexBy="measure"
                colors={['#7b2983']}
                labelTextColor="#fff"
                legends={[]}
                maxValue={35}
                axisBottom={{
                    tickRotation: -45,
                }}
                axisLeft={{
                    tickRotation: -45,
                }}
            />
        </div>
    );
};

BarChart.propTypes = {
    timepoint: PropTypes.object,
};

export default BarChart;
