import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';

import IconPrint from 'cccisd-icons/printer';
import IconView from 'cccisd-icons/eye';

const PrintView = ({ showPrintMode, switchView }) => {
    return (
        <div style={{ textAlign: 'right', marginBottom: '20px' }}>
            <button type="button" className={`${style.printButton} btn btn-info`} onClick={switchView}>
                {showPrintMode ? (
                    <>
                        <IconView /> Report View
                    </>
                ) : (
                    <>
                        <IconPrint /> Print View
                    </>
                )}
            </button>
        </div>
    );
};

PrintView.propTypes = {
    showPrintMode: PropTypes.bool,
    switchView: PropTypes.func,
};
export default PrintView;
