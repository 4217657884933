import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import widgets from '../widgets/widgets.js';
import style from './style.css';
import ReportFooter from '../../common/STAT_Report_Footer/index.js';
import responsesQuery from './responses.graphql';
import PrintView from '../../common/PrintView/index.js';
import Timepoint from './Timepoint/index.js';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const reportColors = {
    green: '#80b361',
    blue: '#169fda',
    yellow: '#e5d031',
    orange: '#f2682b',
    red: '#e0263f',
};

const Component = props => {
    const [loading, setLoading] = useState(true);
    const [showPrintMode, setShowPrintMode] = useState(false);
    const [reportTimepoints, setReportTimepoints] = useState([null]);

    const {
        widgets: {
            reportKey: {
                props: { settings },
            },
        },
    } = props;
    const {
        greenMin,
        greenMax,
        greenLabel,
        greenText,
        blueMin,
        blueMax,
        blueLabel,
        blueText,
        yellowMin,
        yellowMax,
        yellowLabel,
        yellowText,
        orangeMin,
        orangeMax,
        orangeLabel,
        orangeText,
        redMin,
        redMax,
        redLabel,
        redText,
    } = settings;

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const data = response.data.flows.assignmentProgress.devTags;

        const completedDate1 = response.data.flows.assignmentProgress.timepoint1?.total_sts_level_t1 || '';
        const completedDate2 = response.data.flows.assignmentProgress.timepoint2?.total_sts_level_t2 || '';
        const completedDate3 = response.data.flows.assignmentProgress.timepoint3?.total_sts_level_t3 || '';

        const timepoints = [
            { id: 1, completedDate: completedDate1 },
            { id: 2, completedDate: completedDate2 },
            { id: 3, completedDate: completedDate3 },
        ];

        for (const [key, value] of Object.entries(data)) {
            if (key.endsWith('1')) {
                if (!value) {
                    timepoints[0].complete = false;
                } else {
                    timepoints[0][key.slice(0, -1)] = Number(value);
                }
            }
            if (key.endsWith('2')) {
                if (!value) {
                    timepoints[1].complete = false;
                } else {
                    timepoints[1][key.slice(0, -1)] = Number(value);
                }
            }
            if (key.endsWith('3')) {
                if (!value) {
                    timepoints[2].complete = false;
                } else {
                    timepoints[2][key.slice(0, -1)] = Number(value);
                }
            }
        }

        setReportTimepoints(timepoints);
        setLoading(false);
    }

    const findLevel = total => {
        const level = {};

        if (total <= greenMin) {
            level.text = greenText;
            level.color = 'Green';
            level.label = greenLabel;
            level.hex = reportColors.green;
        }
        if (total >= blueMin && total <= blueMax) {
            level.text = blueText;
            level.color = 'Blue';
            level.label = blueLabel;
            level.hex = reportColors.blue;
        }
        if (total >= yellowMin && total <= yellowMax) {
            level.text = yellowText;
            level.color = 'Yellow';
            level.label = yellowLabel;
            level.hex = reportColors.yellow;
        }
        if (total >= orangeMin && total <= orangeMax) {
            level.text = orangeText;
            level.color = 'Orange';
            level.label = orangeLabel;
            level.hex = reportColors.orange;
        }
        if (total > orangeMax) {
            level.text = redText;
            level.color = 'Red';
            level.label = redLabel;
            level.hex = reportColors.red;
        }
        return level;
    };

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };

    if (loading) {
        return <Loader loading type="inline" />;
    }

    const header = (
        <div className={style.headerContainer}>
            <div className={style.logoContainer}>
                my
                <div className={style.logo} />
                level
            </div>
            <div className={style.key}>
                KEY
                <div className={style.scale}>
                    <div className={style.item} style={{ borderTop: `15px solid ${reportColors.green}` }}>
                        <span style={{ color: reportColors.green }}>Green</span> {greenMin}-{greenMax}
                    </div>
                    <div className={style.item} style={{ borderTop: `15px solid ${reportColors.blue}` }}>
                        <span style={{ color: reportColors.blue }}>Blue</span> {blueMin}-{blueMax}
                    </div>
                    <div className={style.item} style={{ borderTop: `15px solid ${reportColors.yellow}` }}>
                        <span style={{ color: reportColors.yellow }}>Yellow</span> {yellowMin}-{yellowMax}
                    </div>
                    <div className={style.item} style={{ borderTop: `15px solid ${reportColors.orange}` }}>
                        <span style={{ color: reportColors.orange }}>Orange</span> {orangeMin}-{orangeMax}
                    </div>
                    <div className={style.item} style={{ borderTop: `15px solid ${reportColors.red}` }}>
                        <span style={{ color: reportColors.red }}>Red</span> {redMin}-{redMax}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div style={{ width: '750px' }}>
            <Report height="600px" downloadFilename="STAT_Module_3_Report" showPagination={showPrintMode}>
                <Page>
                    <BrowserOnly>
                        <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                    </BrowserOnly>
                    <div className={style.flexWrapper}>
                        {header}
                        <div className={style.allTimepoints}>
                            {reportTimepoints.map(tp => {
                                return (
                                    <Timepoint
                                        key={tp.id}
                                        timepoint={tp}
                                        level={tp.STATTotal && findLevel(tp.STATTotal)}
                                    />
                                );
                            })}
                        </div>
                        <ReportFooter />
                    </div>
                </Page>
                <Page>
                    <div className={style.flexWrapper}>
                        {header}
                        <div>
                            <div className={style.page2intro}>
                                The component levels shown on the first page of this report indicate the degree to which
                                you are experiencing intrusion, avoidance, and arousal.
                            </div>
                            <div className={style.board} />
                        </div>
                        <ReportFooter />
                    </div>
                </Page>
            </Report>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    widgetsLoaded: PropTypes.array,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
})(Component);
