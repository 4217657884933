import React from 'react';
import PropTypes from 'prop-types';

import style from './style.css';

const ReportHeader = props => {
    const { moduleId, moduleTitle } = props;

    return (
        <>
            <div className={style.container}>
                <div className={style.logo} />
                <div className={style.reportDetails}>
                    <div className={style.moduleId}>{moduleId}</div>
                    <div className={style.moduleTitle}>{moduleTitle}</div>
                </div>
            </div>
            <div className={style.bar} />
        </>
    );
};

ReportHeader.propTypes = {
    moduleId: PropTypes.string,
    moduleTitle: PropTypes.string,
};

export default ReportHeader;
