import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page, BrowserOnly } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import widgets from '../widgets/widgets.js';
import ReportHeader from '../../common/STAT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/STAT_Report_Footer/index.js';
import responsesQuery from './responses.graphql';
import { renderAlert } from '../../common/reportHelpers.js';
import PrintView from '../../common/PrintView';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const Component = props => {
    const [reportSettings, setReportSettings] = useState({
        studentPopulations: [],
        personalCharacteristics: [],
        schoolEnvironment: [],
    });
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);
    const [showPrintMode, setShowPrintMode] = useState(false);

    const {
        widgets: {
            reportTitle: {
                props: {
                    settings: { moduleId, moduleTitle },
                },
            },
            reportIntro: {
                props: {
                    settings: { reportIntro },
                },
            },
        },
    } = props;

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const data = response.data.flows.assignmentProgress.devTagsLists;

        if (!data.StudentPopulations || !data.PersonalCharacteristics || !data.SchoolEnvironment) {
            setHasModuleProgress(false);
        }

        if (data.StudentPopulations) {
            setHasModuleProgress(true);
            const studentPopResponses = data.StudentPopulations.filter(e => e);
            const personalCharResponses = data.PersonalCharacteristics
                ? data.PersonalCharacteristics.filter(e => e)
                : [];
            const schoolEnvResponses = data.SchoolEnvironment ? data.SchoolEnvironment.filter(e => e) : [];

            setReportSettings({
                studentPopulations: studentPopResponses,
                personalCharacteristics: personalCharResponses,
                schoolEnvironment: schoolEnvResponses,
            });
        }

        setLoading(false);
    }

    const switchView = () => {
        setShowPrintMode(!showPrintMode);
    };

    if (loading) {
        return <Loader loading type="inline" />;
    }

    return (
        <div style={{ width: '750px' }}>
            <Report height="600px" downloadFilename="STAT_Module_2_Report" showPagination={showPrintMode}>
                <Page>
                    <BrowserOnly>
                        <PrintView showPrintMode={showPrintMode} switchView={switchView} />
                    </BrowserOnly>
                    <div className={style.flexWrapper}>
                        <div>
                            <ReportHeader moduleId={moduleId} moduleTitle={moduleTitle} />
                            <div className={style.reportIntro} style={showPrintMode ? { marginTop: '45px' } : {}}>
                                {reportIntro}
                            </div>
                            {hasModuleProgress ? (
                                <div className={style.board}>
                                    <div
                                        className={
                                            reportSettings.studentPopulations.length >= 4
                                                ? style.responses
                                                : style.lessThan4
                                        }
                                        id={style.studentPop}
                                    >
                                        {reportSettings.studentPopulations.map((resp, i) => {
                                            return (
                                                <div
                                                    className={style.response}
                                                    key={i}
                                                    style={showPrintMode ? { fontSize: '10px' } : {}}
                                                >
                                                    {resp}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div
                                        className={
                                            reportSettings.personalCharacteristics.length >= 4
                                                ? style.responses
                                                : style.lessThan4
                                        }
                                        id={style.personalChar}
                                    >
                                        {reportSettings.personalCharacteristics.map((resp, i) => {
                                            return (
                                                <div className={style.response} key={i}>
                                                    {resp}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div
                                        className={
                                            reportSettings.schoolEnvironment.length >= 4
                                                ? style.responses
                                                : style.lessThan4
                                        }
                                        id={style.schoolEnv}
                                    >
                                        {reportSettings.schoolEnvironment.map((resp, i) => {
                                            return (
                                                <div
                                                    className={style.response}
                                                    key={i}
                                                    style={showPrintMode ? { fontSize: '10px' } : {}}
                                                >
                                                    {resp}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : (
                                renderAlert('Please finish the required questions to see this report', 'hourglass')
                            )}
                        </div>
                        <ReportFooter />
                    </div>
                </Page>
            </Report>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    widgetsLoaded: PropTypes.array,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
})(Component);
