import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'stat_m3_dynamic',
    label: 'STAT_M3_Dynamic',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
