import React from 'react';
import IconBrokenHeart from 'cccisd-icons/heart-broken';
import IconHourglass from 'cccisd-icons/hour-glass';

export const renderAlert = (alertText, icon) => {
    const renderIcon = () => {
        switch (icon) {
            case 'hourglass':
                return <IconHourglass spaceRight />;

            case 'brokenHeart':
                return <IconBrokenHeart spaceRight />;

            default:
                return <IconBrokenHeart spaceRight />;
        }
    };

    return (
        <div className="alert alert-info" style={{ textAlign: 'center', marginTop: '1em' }}>
            {renderIcon()}
            {alertText}
        </div>
    );
};
